body {
  margin: 0;
 font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Circe'; /* Name of the font */
  src: local('Circe'), url('./fonts/CRC65.woff') format('woff'); /* Adjust the file path and format as needed */
}
@font-face {
  font-family: 'Roboto-Regular'; /* Name of the font */
  src: local('Roboto-Regular'), url('./fonts/Roboto-Regular.woff') format('ttf'); /* Adjust the file path and format as needed */
}
@font-face {
  font-family: 'Roboto-Medium'; /* Name of the font */
  src: local('Roboto-Medium'), url('./fonts/Roboto-Medium.woff') format('ttf'); /* Adjust the file path and format as needed */
}