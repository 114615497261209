.Password_Login_header_text{
    
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        align-items: center;
      
}
.Password_Login_Textfiled_Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
  }
  .Password_Login_Textfield_grid {
    display: flex !important;
    justify-content: center !important;
  }
  .Password_Login_Form_Textfield{
    margin-top:76px;
  }
