html {
  font-family: 'Roboto', sans-serif;
}
.Welcome_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12vh; /* Push the container down by 30% of the viewport height */
 
}
.Container_one{
  width: 100%;
  max-width: 1140px;
  padding-top: 50px;
}
/* .Container_two{
  width: 80%;
  margin: 0 auto;
} */
.Container_three{
  max-width: 1140px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
/* .Container_four{
  width: 80%;
  margin: 0 auto;
} */
.Container_five{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
 
 
  padding-bottom: 40px;
}
.Container_six{
  
  height: auto;
  max-width: 100%;
}
.Container_seven{
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
/* .Container_eigth{
  width: 80%;
  margin: 0 auto;
} */
.Container_nine{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 180px;
  margin: 0 auto;
}
.Container-ten{
  display: flex;
  justify-content: space-between;
}
.Container_eleven{
  width: 200px;
}
.Container_twelve{
  width: 200px;
}
.Container-thirteen{
  width: 200px;
}
.Container_fourteen{
  width: 200px;
}
.Container_fifteen{
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 45px;
  padding: 8px 15px 8px 15px;
  background-color: rgba(0, 108, 183, 0.17);
  
}
.Container_sixteen{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Container_seventeen{
  max-width: 1920px;
  width: 100%;
}
.FAQ_one{
  width: 100%;
  max-width: 1140px;
  padding-top: 50px;
}
/* .FAQ_two{
  width: 80%;
  margin: 0 auto;
} */
.FAQ_three{
  width: 100%;
  max-width: 1140px;
  padding-top: 50px;
}
/* .FAQ_four{
  width: 80%;
  margin: 0 auto;
} */
.Pricing_one{
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Pricing_two{
  width: 100%;
  max-width: 1140px;
  padding-top: 50px;
}
/* .Pricing_three{
  width: 80%;
  margin: 0 auto;
} */
.Pricing_four{
  max-width: 1140px;
  width: 100%;
  padding-top: 70px;
  justify-content: center;
  display: flex;
}
.Pricing_five{
  width: 100%;
}
.Pricing_six{
  max-width: 1140px;
  width: 100%;
  padding-top: 180px;
  justify-content: center;
  display: flex;
}
/* .Pricing_seven{
  width: 80%;
} */
.Pricing_eigth{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Home_one{
  max-width: 1140px;
  padding-top: 50px;
  width: 100%;
}
/* .Home_two{
  width: 80%;
  margin: 0 auto;
} */
.Home-three{
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
/* .Home_four{
  width: 80%;
  margin: 0 auto;
} */
.Home_five{
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
.Home_six{
  max-width: 1140px;

}
/* .Home_seven{
  width: 80%;
  margin: 0 auto;
} */
.Home_eigth{
  padding-top: 72px;
  padding-bottom: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Home_nine{
  display: flex;
  gap: 40px;
}
.Home_ten{
  padding-top: 25px;
}
.Home_eleven{
  padding-top: 25px;
}
.Home_twelve{
  padding-top: 25px;
}
.Home_thirteen{
  padding-top: 25px;
}
.Home_fourteen{
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 60px;
  /* padding-top: 180px; */
}
.Home_fifteen{
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 150px;
  padding-top: 180px;
}
.Home_sixteen{
  display: flex;
  flex-direction: column;
}
.Home_seventeen{
  display: flex;
  flex-direction: column;
}
.Home-eighteen{
display: flex;
justify-content: center;
align-items: center;
padding-top: 180px;
}
/* .Home_nineteen{
  display: flex;
  flex-direction: column;
} */
/* .Home-nineteen_img{
  width: 60px;
  height: auto;
  padding-bottom: 16px;
} */
.Welcome {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Welcome_join_text{
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  /* typography/h4 */
  font-family: Roboto-Regular;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px; 
  margin-bottom: 40px;
}
.Welcome_paper {
  padding: 33px 30px;
  /* height: 22rem !important; */
  width: 310px !important;
  background-color:rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
 
  flex-direction: column;
  column-gap: 6px;

}
.contactmain{
max-width: 1140px;
width: 100%;
padding-top: 50px;
}
/* .contactmain_2{
  width: 80%;
  margin: 0 auto;
} */
.contactmain_3{
  width: 100%;
  max-width: 1140px;
  padding-top: 135px;
  padding-bottom: 160px;
}
/* .contactmain_4{
  width: 80%;
  margin: 0 auto;
} */
.contactmain_5{
  display: flex;
  padding-bottom: 50px;
  align-items: center;
  gap: 20px;
}
.contactmain_6{
  width: 100%;
  padding: 20px 0px 20px 0px;
}
.contactmain_7{
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding-top: 50px;
}
.contactmain_8{
  display: flex;
  padding-bottom: 50px;
  /* padding-top: 50px; */
  align-items: flex-start;
  gap: 20px;
}
.Welcome_paper1 {
  /* height: 22rem !important; */
  padding: 33px 30px;
  width:310px !important;
  background-color:rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
 
  flex-direction: column;
  column-gap: 6px;

}
.welcome_Button_div{
  display: flex;
  gap: 20px;
}
.Welcome_button{
  margin-top: 20px;
}
.Welcome_img{
width: 146px;
height: 136px;
}
.Welcome_desc{
  text-align: left;

font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto-Regular;
font-size: 20px;
font-style: normal;
font-weight: 400;
 line-height: 133.4%;  
 margin-top: 40px;
}
.clicked {
  border: 1px solid rgba(0, 95, 204, 1) !important;
  opacity: 0.7 rgba(0, 95, 204, 1) !important;
}
.clicked-text {
  color: rgba(0, 95, 204, 1);
}
.Welcome__already{
  font-size: 14px;
  margin-top: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0,0.6);
}
.Welcome_link{
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #006CB7;
  margin-left: 4px;
}
.mainText{
  color: var(--White, #FFF);
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 94px; /* 91% */
  letter-spacing: -0.889px;
  margin-bottom: 25px;
}
.vertical-divider {
  width: 2px; /* Adjust the width of the divider as needed */
  background-color: #DEDFE1; /* Adjust the background color of the divider as needed */
  margin: 0px 20px; /* Adjust the margin as needed to control the spacing between the divs */
}
.mainText2{
  color: #FFF;

  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
}
.Trusted {
  height: auto;
  
  display: flex;
  justify-content: space-between;

  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap when screen size is reduced */
  margin-top: 40px;
  margin-bottom: 40px;
}

.trustedImage {
  width: 150px;
  height: fit-content;
 
 
}

.students {
 
  background-color: rgba(0, 108, 183, 0.10);
  height: auto; /* Adjust height property to allow content to expand */
  border-radius: 23px;
  display: flex;
 flex-direction: column;
  justify-content: space-between;
 padding: 35px;
  
}



/* Apply flex-wrap for screens larger than 768px (adjust the breakpoint as needed) */
/* @media (max-width: 769px) {
  .Trusted {
    flex-wrap: wrap;
  }
  
} */
/* .trustedImage{
  width: 150px;
}
.students{
  width: 100vw;
  margin-left: 90px;
  margin-right: 90px;
  background-color:  rgba(0, 108, 183, 0.10);
  ;
  height: 248px;
  border-radius: 23px;
  display: flex; 
  align-items: center;                 

} */
.students1{
 
 justify-content: center;
 
 
}
.students2{
  
 
  display: flex;
  flex-direction: column;
  

}
.students3{

display: flex;
flex-direction: column;
 
}
.studentsmainText{
  color: var(--material-theme-sys-light-primary-1, #006CB7);
  
  font-family: Circe;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -0.556px;
}
.studentssecondaryText{
  color: #2F2D3B;
font-family: Roboto-Regular;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */

}
.students2mainText{
  color: #0F0049;
font-family: Roboto-Regular;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.667px;
}
.featuresMain {
  width: 100%;
 
  /* background-color: rgba(0, 108, 183, 0.10); */
  height: 'auto'; /* Adjust height property to allow content to expand */
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 180px;
}
.featuresMainTwo {
  width: 100%;
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 180px;
}
.CollegeText{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.25px;
}
.appIcon {
 
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 79px;
  padding-top: 79px;
}

/* Hide the scrollbar */
.appIcon::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar width */
}

/* Optional: You can style the scrollbar thumb and track if needed */
.appIcon::-webkit-scrollbar-thumb {
  background: transparent; /* Hide the scrollbar thumb */
}

.appIcon::-webkit-scrollbar-track {
  background: transparent; /* Hide the scrollbar track */
}


.appIconImage {
  padding: 0px;
  /* Add your image styling here */
}
.CommentContainer{
  display: flex;

  /* background-color: #006CB7; */
 gap: 106px;
 
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;
  
  
}
.CommentContainerLeft{
 
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: aqua; */
  flex-wrap: nowrap;
 
}
.CommentContainerLeft-Child{
  display: flex;
 align-items: center;
 
}
.StarImagee{
  padding-bottom: 10px;
}
.Profilecontainer{
  display: flex;
  padding-top: 34px;
  align-items: center;
  gap: 20px;
}
.Starimagetext{
  color: var(--Gray0, #2D2F31);

font-size: 23px;
font-family: Roboto;
font-weight:500;
line-height: 34px; /* 141.667%CommentContainerRight */

}
.CommentContainerRight{
 
  width:   -webkit-fill-available;
  border-radius: 20px;
}
.CommentContainerLeftTextMain{
  color: var(--Gray0, #2D2F31);
font-family: Roboto-Regular;
font-size: 35px;
font-style: normal;
font-weight: 600;
line-height: 50px; 
letter-spacing: -0.556px;
}
.CommentContainerLeft-Child-mainText{
  color:  #2D2F31;

font-size: 24px;
font-style: normal;
font-weight: 700;
}
.CommentContainerLeft-Child-secondaryText{
  color:  #2D2F31;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;

}
.featuresMain1 {
  display: flex;
  border-radius: 20px;
  background: rgba(0, 108, 183, 0.15);
  
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
}
.College {
  border-radius: 26px;
  background: #006CB7;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
 
}

.featuresMain2 {
  display: flex;
  border-radius: 16px;
  /* background: rgba(0, 108, 183, 0.15); */
  width: 10px;
  align-items: center;
  justify-content: center;
}

.featuresMain2 {
  border-radius: 16px;
  /* background: rgba(0, 108, 183, 0.15); */
  width: 65%;
}
.featuresMain21 {
  display: flex;
  border-radius: 16px;
  /* background: rgba(0, 108, 183, 0.15); */
  width: 10px;
  align-items: center;
  justify-content: center;
}

.featuresMain21 {
  border-radius: 16px;
  /* background: rgba(0, 108, 183, 0.15); */
  width: 100%;
}

.featuresMain3 {
  border-radius: 16px;
  /* background: rgba(0, 108, 183, 0.15); */
  width: 650px;
  padding-top: 15px;
}
.featuresMain1Text{
  color: var(--Main1, #006CB7);
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
padding: 8px 22px;
}
.dedicatedText{
  color: #000;
text-align: center;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
}

.featuresMain2Text {
  color: var(--Gray0, #2D2F31);
text-align: center;
font-family: Circe;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 120% */
letter-spacing: -0.722px;
padding-top: 25px;
}
.featuresMain2Text2 {
  color: #2D2F31; /* Updated color value */
  text-align: center;
  font-family: Circe;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  padding-top: 25px;

}
.featuresMain3Text {
  color: #2D2F31; /* Updated color value */
  text-align: center;
 display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.services{
  padding-top: 84px;
  
  /* background-color: rgba(0, 108, 183, 0.10); */
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content:space-between;
 
  
 

}
.sevicesTextMain{
  color: var(--Gray0, #2D2F31);
text-align: center;
font-family: Roboto;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 17px;
}
.sevicesTextSecondary{
  color: var(--Gray0, #2D2F31);
text-align: center;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
padding-top: 12px;
}
.servicesImage{
  padding-bottom: 30px;
}
.linkText{
  color: var(--Gray0, #2D2F31);
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.25px;
text-decoration: none;
}
.services1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.services2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 50px;
}
.services3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 50px;
}
.services4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 50px;
}

.sevicesText{
  padding-top: 10px;
  border-top: 1px solid #DEDFE1;
  display: flex;
  flex-direction: column;

}
.trackApplication{
  /* width: 100vw;
  margin-left: 90px;
  margin-right: 90px;


  /* border-radius: 23px; */ 
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  

}
.contactusTextsecondary{
  color: var(--Gray0, #2D2F31);
font-family: Circe;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.contactusText{
  color: var(--material-theme-sys-light-primary-1, #006CB7);
  font-family: Roboto-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.trackApplicationupdate{
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  gap: 160px;

}
.trackApplicationLeft2{
  
  /* background-color: #006CB7; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.trackApplicationLeft{
 
 
  /* background-color: #006CB7; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;

}
.trackApplicationRight{

  height: auto;
  /* background-color: #b700ae; */
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  
}
.trackApplicationflex{
  display: flex;
  /* justify-content: space-between; */
 
 
 
 
}
.feature_maindiv{
 display:'flex';
 flex-direction: column;
 

}
/* .feature_maindiv2{
  display: flex;
  justify-content: space-between;
  gap: 48px;
} */
/* .trackApplicationflex2{
  display: grid;
  grid-template-columns: repeat(2,1fr);

  
 
 
 
 
} */

/* .trackApplicationflex2Item{
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
} */
/* .trackApplicationflex2Item-last{
  display: flex;
  
  padding: 10px 10px 0px 10px;
  gap: 4px;
  align-items: center

} */
.trackApplicationLeftTextMain{
  color: var(--student, #1C9AD6);
font-family: Circe;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 55px;
padding-top: 15px;
padding-bottom: 21px;


}
.trackApplicationLeftTextMain2{
  color: var(--student, #006CB7);
font-family: Circe;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 55px;
padding-top: 15px;
padding-bottom: 21px;


}
.trackApplicationLeftTextSecondary{
  color: var(--Gray0, #2D2F31);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px;
padding-bottom: 31px;

}
/* .trackApplicationflexMain{
  color: var(--Gray0, #2D2F31);
  
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 6px;

} */
/* .trackApplicationflexMainSecondary{
  color: var(--Gray0, #2D2F31);
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 300;


} */
.trackApplicationLeft2{
  padding-left: 40px;
  width: 45vw;
  height: 500px;
  /* background-color: #006CB7; */
  display: flex;
  flex-direction: column;

}
.risingStars{
  display: flex;

  /* background-color: #006CB7; */


  align-items: center;
  padding-top: 180px;

}
.risingStarsText{
  color: var(--Gray0, #2D2F31);
font-family: Circe;
font-size: 50px;
font-style: normal;
font-weight: 700;

letter-spacing: -0.722px;
}
.risingStarsContainer{
  display: flex;
 
  /* background-color: #006CB7; */

 
  align-items: center;
 padding-top: 64px;
  /* background-color: #006CB7; */
  justify-content: space-between;
gap: 30px;
}
.risingStarsContainerLeft{
  display: flex;
  
  /* background-color: #0F0049; */
  flex-direction: column;

}
.risingStarsContainerRight{
  display: flex;
  
  /* background-color: #00491c; */
  flex-direction: column;

}
.companyTitle{
  color: var(--White, #FFF);
  font-family: Circe;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 100% */
  letter-spacing: -0.889px;

}
.companyDescription{
  color: var(--White, #FFF);
font-family: Roboto-Regular;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
margin-top: 24px;

}
.companyDescription2{
  color: var(--White, #FFF);
font-family: Roboto-Regular;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
width: 80%;

}
.AboutusDetail{
  color: var(--material-theme-sys-light-on-primary, #58595B);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
}
.missionText{
  /* color: #0F0049; */
  color: var(--material-theme-sys-light-primary-1, #006CB7);
  text-align: center;
  font-family: Circe;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 120% */
  letter-spacing: -0.556px;
}
.missionRates{
  color: var(--material-theme-sys-light-primary-1, #006CB7);
text-align: center;
font-family: Circe;
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.722px;
}
.missionNames{
  color: #0F0049;
text-align: center;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height:15px;
}
.HumanImage{
  max-width: 100%;
  border-radius: 20px;
}
.HumanName{
  color: var(--Gray0, #2D2F31);
text-align: center;
font-family: Roboto-Regular;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 20px;
padding-bottom: 10px;
}
.ExploreText{
  color: var(--Main1, #006CB7);
font-family: Circe;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.25px;
text-decoration: none;

}
.ExploreTextdec{
  color: #000;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
padding-bottom: 20px;
}
.top-sub {
  vertical-align: top;
  color: var(--Gray0, #2D2F31);
font-family: Roboto-Regular;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.25px;
}
.paymentValidityText{
  color: var(--Gray0, #2D2F31);
text-align: center;
font-family: Circe;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.ValidityTextecondaryGrid1{
  color: var(--material-theme-sys-light-primary-1, #006CB7);
text-align: center;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
}
.ValidityTextecondaryGrid2{
  color: var(--material-theme-sys-light-pending, #EF6C00);
text-align: center;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
}
.ValidityTextecondaryGrid3{
  color: var(--material-theme-sys-light-primary-1, #006CB7);
text-align: center;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
}
.paymentPrice{
  color: var(--Gray0, #2D2F31);
font-family: Circe;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.889px;
}
.planstitle{
  color: var(--Gray0, #2D2F31);
font-family: Roboto-Regular;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom:15px;
}
.plansDec{
  color: var(--Gray0, #2D2F31);
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
letter-spacing: 0.178px;
}
.priceImage{
  margin-right: 15px;
}
.buttonText{
  color: var(--White, #FFF);
text-align: center;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.25px;
text-decoration:none;
}
.creditText{
  color: #82808A;
text-align: center;
font-family: Satoshi;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.aboutus-one {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutus-two{
  padding-top: 100px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items:stretch !important;
  padding-bottom: 30px !important;
}
.aboutus_three {
  display: flex !important;
  padding-bottom: 20px !important;
  justify-content: space-between !important;
  /* gap: 80px !important; */
  width: fit-content;
}
.aboutus_fourth {
  display: flex !important;
  padding-top: 80px !important;
}
.aboutus_five {
  text-align: center !important;
 
}
.aboutus_six {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 33px !important;
}
.aboutus_seven {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 33px !important;
}
.aboutus_eight {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 180px !important;
  /* Note: You have 'alignItems' defined twice; I removed the duplicate entry. */
}
.aboutus_nine {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px !important;
  text-align: center !important;
}
.aboutus_ten {
  display: flex !important;
  /* justify-content: space-between !important; */
  padding: 48px 53px 45px 53px !important;
  background-color: #F6F5F8!important;
  border-radius: 15px !important;
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
.aboutus_eleven {
  display: flex !important;
  gap: 30px !important;
  align-items: center !important;
}
.aboutus_main {
  background-color: #006CB7 !important;
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}


/* styles.css */

.contact_one {
  background-color: #006CB7 !important;
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  /* max-width: 1140px !important; */
  margin: 0 auto !important;
}
/* styles.css */

.contact_two {
  padding-top: 100px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 50px !important;
}
/* styles.css */

.contact_three {
  display: flex !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 30px !important;
}
/* styles.css */

.contact_four {
  background-color: rgba(0, 108, 183, 0.17) !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 50px !important;
}
/* styles.css */

.contact_five {
  display: flex !important;
  flex-direction: column !important;
}
/* styles.css */

.contact_six {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
  justify-content: space-between !important;
  align-self: normal !important;
}
/* styles.css */

.contact_main {
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  /* max-width: 1140px !important; */
  margin: 0 auto !important;
}
/* styles.css */

.contact_icon {
  color: #006CB7 !important;
  font-size: 3rem !important;
}
.contact_textinput{
  margin-top: 20px !important;
}
/* styles.css */

.contact_button{
  width: 150px !important;
  margin-top: 20px !important;
  padding: 8px 16px !important;
  box-shadow: none !important;
}
/* styles.css */

.faq_one {
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
/* styles.css */

.faq_two {
  background-color: #006CB7 !important;
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
/* styles.css */

.faq_three {
  padding-top: 100px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 50px !important;
}
/* styles.css */

.faq_four {
  display: flex !important;
  padding-bottom: 20px !important;
  flex-direction: column !important;
  text-align: center !important;
}
/* styles.css */

.faq_five {
  height: auto !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  padding-top: 100px !important;
  padding-bottom: 200px !important;
}
/* styles.css */

.Homemain_one {
  min-height: 90vh !important;
  display: flex !important;
  flex-direction: column !important;
  align-content: flex-start !important;
  justify-content: center !important;
  padding-bottom: 30px !important;
}

/* styles.css */

.Homebutton_one {
  margin-top: 50px !important;
  width: 208px !important;
height: var(--7, 56px) !important;
flex-shrink: 0 !important;
border-radius: var(--borderRadius, 4px) !important;
background: #006CB7 !important;
color: var(--material-theme-white, #FFF) !important;
text-align: center !important;
font-family: Roboto !important;
font-size: 18px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
letter-spacing: -0.25px !important;
text-transform: none !important;

}

.Homebutton_one:hover {
  background-color: white !important;
  color: #006CB7 !important;
}
/* styles.css */

.pricing_one {
  padding: 32px !important;
  border: 0.25px solid #d9d9d9 !important;
  border-radius: 16px !important;
 /* background-color: "#FFFF" !important; */
  margin-right: 40px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 20% !important;
}
/* styles.css */
.ValidityTextecondaryGrid12{
  text-align: center;
font-family: Roboto-Medium;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
}
.pricing_two {
  display: flex !important;
  flex-direction: column !important;
  text-align: center !important;
}
/* styles.css */

.pricing_three {
  display: flex !important;
  align-items: center !important;
  padding-top: 16px !important;
}
/* styles.css */

.pricing_four {
  height: 50px !important;
  width: 100% !important;
  
  border-radius: 4px !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 40px !important;
  text-align: center !important;
}
/* styles.css */

.pricing_five {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
/* styles.css */

.pricingmain_one {
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
/* styles.css */

.pricingmain_two {
  background-color: #006CB7 !important;
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
/* styles.css */

.pricingmain_three {
  padding-top: 100px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 50px !important;
}
/* styles.css */

.pricingmain_four {
  display: flex !important;
  padding-bottom: 20px !important;
  flex-direction: column !important;
  text-align: center !important;
}
/* styles.css */

.pricingmain_five {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.actualpaymentPrice{
  color: var(--material-theme-sys-light-on-primary, #58595B);
text-align: center;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 108.333% */
text-decoration-line: strikethrough;
/* padding-top: 10px; */
padding-bottom: 30px;
}
.companyDescriptionLink{
  color:#FFF ;
}
.Homebutton_oneLink{
  text-decoration: none;
  color: var(--material-theme-white, #FFF);
}
.feature_maindiv_Button{
  display: flex !important;
  width: 218px !important;
  padding: 8px 22px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: var(--borderRadius, 4px) !important;
background: #1C9AD6 !important;
box-shadow: none !important;
}
.feature_maindiv_Link{
  text-decoration: none !important;
}
.Home_fifteen_Button{
  display: flex !important;
width: 218px !important;
padding: 8px 22px !important;
flex-direction: column !important;
justify-content: center !important;
align-items: center !important;
border-radius: var(--borderRadius, 4px) !important;
background: var(--material-theme-sys-light-primary-1, #006CB7) !important;
box-shadow: none !important;
}
.trustedImageText{
  color: var(--Gray1, #808991) !important;
font-family: Roboto-Regular !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;   
letter-spacing: -0.222px !important;
}
.featuresMain1Text_1{
  color: var(--student, #1C9AD6);
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
padding: 8px 22px;
}

@media (min-width: 681px) {
  .Menu_icon{
      display: none  !important;
  } 
  
  }
 
@media (max-width: 680px) {
  .Welcome_grid_Main{
    margin-bottom: 60px !important;
  }
  .Welcome_img_applyupp{
    width: 180px;
  }
  .Welcome_join_text {
font-size: 25px;
  }
  .HumanName {
    font-size: 20px;
    line-height: 25px;
  }
  .dedicatedText{
    font-size: 16px;
    line-height: 24px;
  }
  .aboutus_eight{
    padding-top: 100px !important;
  }
  .missionText{
    font-size: 40px;
    line-height: 45px;
  }
  .Container_nine{
    padding-top: 100px;
  }
  .Home-eighteen{
    padding-top: 100px;
  }
  .aboutus-two{
    padding-top: 60px !important;
  }
  .mainText2{
    font-size: 18px;
    line-height: 26px;
  }
  .mainText{
    font-size: 50px;
    line-height: 60px;
  }
  .Welcome_paper {
    padding: 33px 30px;
    /* height: 22rem !important; */
    width: 250px !important;
    background-color:rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.4rem;
    display: flex;
    justify-content: space-between;
   
    flex-direction: column;
    column-gap: 6px;
  
  }
  .Welcome_paper1 {
    /* height: 22rem !important; */
    padding: 33px 30px;
    width:250px !important;
    background-color:rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.4rem;
    display: flex;
    justify-content: space-between;
   
    flex-direction: column;
    column-gap: 6px;
  
  }
  .welcome_Button_div{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Home_seven{
    padding-left: 20px;
    padding-right: 20px;
  }
  .Homemain_one{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .studentsmainText{
    font-size: 29px;
    line-height: 34px;
  }
  .studentssecondaryText{
    font-size: 15px;
    line-height: 20px;
  }
  .featuresMain3Text{
    font-size: 16px;
    line-height: 24px;
  }
  .sevicesTextMain {
    font-size: 18px;
  }
  .sevicesTextSecondary{
    font-size: 16px;
    line-height: 24px;
  }
  .featuresMain2Text2{
    font-size: 38px;
    line-height: 40px;
  }
  .CommentContainerLeftTextMain{
    font-size: 26px;
    line-height: 37px;
  }
  .CommentContainerLeft-Child-mainText{
    font-size: 16px;
  }
  .CommentContainerLeft-Child-secondaryText{
    font-size: 13px !important;
  }
  .risingStars{
    padding-top: 100px;
  }
  .CommentContainerLeft{
    gap: 35px;
  }
  .Home_eigth{
    
    display: grid;
    grid-template-columns: repeat(1,1fr);
    justify-items: center;
    gap: 20px ;
   
  }
  .contactmain_7{
    padding-top: 10px;
  }
  .contactmain_8{
    padding-bottom: 10px;
  }
  .Home_one{
    padding-top: 35px;
  }
  .contactusTextsecondary{
    font-size: 16px;
  }
  .contactmain_3{
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .companyTitle{
    font-size: 40px;
    line-height: 45px;
  }
  .Home_five{
    display: block !important;
    padding: 0px !important;
  }
  .Homepage_Text_div{
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
  }
  .Home_fourteen{
    display: flex !important;
    flex-direction: column !important;
    padding-top: 100px !important;
  }
  .trackApplicationLeftTextSecondary{
font-size: 16px;
line-height: 24px;
  }
  .trackApplicationLeftTextMain{
    font-size: 40px;
    line-height: 45px;
  }
  .trackApplicationLeftTextMain2{
    font-size: 40px;
    line-height: 45px;
  }
  .featuresMain{
    padding-top: 100px;
  }
  .featuresMain2Text{
    font-size: 40px;
    line-height: 45px;
  }
  .Home_fifteen{
    display: flex !important;
    flex-direction: column !important;
    padding-top: 100px !important;
  }
  .featuresMain3{
    width: fit-content;
  }
  .services {
    gap: 40px !important;
    display: grid  !important;
    grid-template-columns: repeat( 1,1fr) !important;
  }
  .services3{
    padding-left: 0px;
  }
  .services2{
    padding-left: 5px;
  }
  .services4{
    padding-left: 0px;
  }
  .appIcon{
    display: grid !important;
    grid-template-columns: repeat(1,1fr) !important;
    justify-items: center !important;
    gap: 20px !important;
  }
  .featuresMainTwo{
    margin-bottom: 100px;
  }
  .CommentContainer{
    display: flex !important;
    flex-direction: column !important;
    gap: 70px !important;
  }
/* .CommentContainer{
  font-size: 45px;
  line-height: 40px;
} */
.Starimagetext{
  font-size: 16px;
  line-height: 24px;
}
  .risingStarsContainer{
    display: flex;
    flex-direction: column;
  
  }
  .aboutus_three {
    display: flex !important;
    flex-direction: column !important;
    gap: 40px !important;
  }
  .companyDescription2{
  font-size: 16px;
  line-height: 24px; /* 155.556% */
  width: 100%;
  
  }
  .AboutusDetail{
    font-size: 16px;
    line-height: 24px;
  }
  .Container_sixteen {
    display: grid;
    grid-template-columns: repeat(1,1fr);
  }
.aboutus_ten{
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
}
.About_us_textdiv{
  padding-left: 0px !important;
}
.Container_eigth{
  padding-left: 20px;
  padding-right: 20px;
}
.Container_four{
  padding-left: 20px;
  padding-right: 20px;
}
.pricing_one{
  margin-right: 0px !important;
}
.Pricing_uper_div{
  width: 100% !important;
}
.pricingmain_five{
  gap: 40px;
}
.Pricing_five{
  padding-left: 20px;
  padding-right: 20px;
}
.Pricing_eigth{
padding-left: 20px;
padding-right: 20px;
}
.faq_five{
  padding-left: 20px !important;
padding-right: 20px !important;
}
.contact_three{
display: flex !important;
flex-direction: column !important;
padding-left: 20px !important;
padding-right: 20px !important;
} 
.contact_four{
  padding: 20px !important;
}

}



/* Add more media queries for smaller screens as needed */
