.LogoImage{
  width: 140px;
  height: 50px;

}
.Services{
  text-decoration: none;
  color: var(--material-theme-sys-light-on-primary, #58595B);
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 20px;
}
.SocialImages{
  padding-left: 10px;
}
.CopyrightText{
  color: #2F2D3B;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
}
.Button{
  background-color: #58595B;
  width: 150px;
  height: 100px;
}
.FooterMainText{
  color: var(--White, #FFF);
text-align: center;
font-family: Circe;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 62px; /* 119.231% */
letter-spacing: -0.722px;
}
.FootersecondaryText{
  color: var(--White, #FFF);

font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
align-items: center;
}
.Button1Text{
  color: var(--Gray0, #2D2F31);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.25px;
text-decoration: none;
}
.Button2Text{
  color: var(--White, #FFF);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.25px;
text-decoration: none;
}



@media (max-width: 680px) {
  .Footer_Bottom_div{
    padding-top: 0px !important;
  }
  .footer_button_Maindiv{
    padding-bottom: 60px !important;
  }
  .FootersecondaryText{

  font-size: 16px;

  line-height: 22px; /* 155.556% */
  align-items: center;
  }
  .FooterMainText{

  font-size: 38px;

  line-height: 46px; /* 119.231% */
  letter-spacing: -0.722px;
  }
.Footer_Padding_div{
  padding-left: 20px;
  padding-right: 20px;
}
.FooterProducts{
  display: none !important;
}
.Footer_Button_div{
  width: 120px !important;
}
.Footer_text_div{
  width: auto !important;
}

}