
/* styles.css or styles.scss */

.custom-dropzone-root {
    /* Set the desired height here */
    min-height: 100px;
    border: 2px dashed #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 8px;
    cursor: pointer;
  }
  
  /* Style the hover effect when files are dragged over the dropzone */
  .custom-dropzone-root:hover {
    background-color: #f0f0f0;
  }
  
  /* Style the text inside the dropzone */
  .custom-dropzone-root p {
    color: #333;
  }
  
  /* Style the upload icon inside the dropzone */
  .custom-dropzone-root .MuiSvgIcon-root {
    font-size: 30px;
    color: #666;
  }
  
  /* Style the file names and icons in the list of selected files */
  .custom-dropzone-root .MuiList-root {
    max-height: 100px; /* Adjust as needed */
    overflow-y: auto;
  }
  
  .custom-dropzone-root .MuiListItem-root {
    padding: 4px 0;
  }
  
  .custom-dropzone-root .MuiListItemSecondaryAction-root {
    margin-right: 10px;
  }
  
  /* Style the remove button */
  .custom-dropzone-root .MuiButtonBase-root.MuiListItemSecondaryAction-root {
    color: red;
  }
  
  /* Style the progress bar when files are uploading */
  .custom-dropzone-root .MuiLinearProgress-root {
    width: 100%;
    margin-top: 10px;
  }

  .css-fksjaj{
    justify-content: space-around;
    align-items: center;
    
  }
  .css-1agvk75 {
    min-height: 150px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
 
  }
  .css-17pbpn5-MuiTypography-root{
    font-size: 1rem !important;
    margin-bottom:2px !important ;
    margin-top: 10px !important;
  }
  .Test_mainBox{
 width: 50%;
 
  }
  /* .Test_paddingBox{
    padding-top: 10pc;
  } */
  /* .Test_paddingBox2{
    padding-top: 5pc;
  } */
  .Test_paddingDiv{
    padding-top: 20px;
  }
 
  .Test_FlexRow{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  
  }
  .Test_FlexRow_workExperience{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  
  }
  .Test_FlexRow_honors{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
  .Test_FlexRow_highschool{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
  .Test_FlexRow_recomenders{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  
  }
  .Test_FlexRow1122{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  
  }
  .Test_FlexRow21{
    flex-direction: row;
    display: flex;
    align-items: center;
  gap: 20px;
  width: 102%;
    justify-content: center;
  
  }
  .Test_FlexRow_special2{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    width: 100%;

  }
  .Test_FlexRow_special234{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    width: 100%;

  }
  .Test_FlexRow_special{
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;

  }
  .Test_FlexRow1{
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin-top: 16px;
  }
  .Test_FlexRow2{
    flex-direction: row;
    display: flex;
  
    gap: 20px;
   
    padding-top: 20px;
  }
  .Test_FlexColumn2{
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding-top: 20px;
  }
  .Test_FlexColumn{
    flex-direction: column;
    display: flex;

    gap: 20px;

    padding-top: 20px;
  }
  .Test_buttonDiv{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    
    gap: 10px;
    padding-top: 50px;
  }
  .Test_buttonDiv2{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-right: 20px;
    gap: 10px;
    padding-top: 100px;
  }
  .Test_BoxDiv{
    display: flex;
    justify-content: center;
    height: 80%;
    margin-top: 8vh;
  }
  .Test_DivColumn{
display: flex;
flex-direction: column;
  }
  .Test_DivColumn_center{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  }
  .collaborator_colleges_maindiv{
    width:auto !important ;
  }
  
  @media (max-width: 680px) {
    .Test_FlexRow_highschool{
      flex-direction: column;
      gap: 5px;
    }
    .Colleges_Status_div{
      display: flex !important;
      flex-direction: column !important;
    }
    .Collaborator_tab_toppadding{
      padding-top: 40px;
    }
    .Colleges_status{
      margin: 0px !important;
    }
    .Collaborator_editprofile_form{
      width: 100% !important;
    }

    .Collaborator_editprofile_button{
width: 100% !important;
    }
    .Collaborator_summary_maindiv{
      width: 100% !important;
    }
    .Collaborator_Summary_button{
      width: 100% !important;
    }
    .Collaborator_Activities_maindiv{
      width: 100% !important;
    }
    .Test_FlexRow_special2{
      flex-direction: column;
      display: flex;
      gap: 20px;
    

    }
    .Activities_endDate{
      width: 100% !important;
    }
    .Collaborator_activities_frorm{
      display: flex;
      flex-direction: column;
      gap: 0px !important;
    }
    .Button_activities{
      width: 100% !important;
    }
  .collaborator_colleges_maindiv{
    width:-webkit-fill-available !important ;
  }
  .Test_FlexRow{
    flex-direction: column;

  
  }
  .Test_FlexRow_workExperience{
    flex-direction: column;
    gap: 3px; 
  }
  .Activities_Voluntier_hour{
    width: 100% !important;
  }
  .Test_FlexRow_honors{
    flex-direction: column;
    gap: 3px; 
  }
  .Test_FlexRow_recomenders{
    flex-direction: column;
    gap: 5px;
  }
  .Collaborator_aid_pkg{
    width: 100% !important;
  }
  .Collaborator_Total_pkg{
    width: 100% !important;
  }
  .Test_FlexRow1122{
    flex-direction: column !important;
gap: 5px !important;
  
  }
  .Test_FlexRow_special234{
    flex-direction: column;
    display: flex;
   
    gap: 20px;
  

  }
  .Collaborator_highschool_form{
    width: 100% !important;
  }
  .Test_FlexRow1{
    flex-direction: column;
    
    gap: 20px;
  
  }
  .Test_FlexRow21{
    flex-direction: column;
  gap: 0px;
  width: 100%;
  
  }
  .Collaborator_Honors_form{
    width: 100% !important;
  }
  .Collaborator_honors_tab2{
    width: auto !important;
  }
  .Collaborator_honors_tab1{
    width: auto !important;
  }
  .Collaborator_testing_form{
    width: 100% !important;
  }
}