.admin_Login_Grid{
    background-image: url('../../assets/img/loginbg.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;

}
.admin_Login_text_area{
    flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;
  margin-top: 60px;
}
.admin_Login_logo_img{
    width: 40%;
    height: auto;
    display: block;
}
.admin_Login_Bottom_box{
    display: flex;
    flex-direction: column;
    padding: 60px;
}
.admin_Login_Bottom_div{
    display: flex;
    align-items: center;
    gap: 8px;
}
.admin_Login_Bottom_text{
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 117.5%; /* 25.85px */
    letter-spacing: 0.25px;
    opacity: 0.6;
}
.admin_Login_Textfield_grid{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 100vh;
}
.admin_Login_Textfiled_Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.admin_Login_header_text{
    display: flex;
    flex-direction: column;
   padding-bottom: 15px;
}
.admin_Login_Welcome{
    color: #006CB7;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.admin_Login_Form_Textfield{
    margin-top: 16px;
}
.admin_Login_Welcome_text{
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
}
.admin_Login_button{
    margin-top: 16px !important;
    margin-bottom: 6px !important;
}
.admin_topbar_Container{
background-image: url('../../assets/img/topbar.png') !important;
background-repeat: no-repeat !important;
background-size: cover !important ;
background-position: center !important;
height: 8vh;
align-items: center !important;
justify-content: space-between !important;
display: flex;
}
.admin_topbar_subContainer{
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0px 20px !important;

}
.admin_topbar_Logo{
    width: auto;
    height: 25px;
}
.admin_Topbar_header_text{
    color: #FFF;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  
    letter-spacing: 0.15px;
}
.admin_dashboard_Container{
    background-image: url('../../assets/img/bg-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    padding: 20px;
    display: flex;
    flex: auto;
    /* height: 100vh; */

}
.admin_dashobard_container_Left{
    height: 100%;
    display: flex;
    padding: 4px;
    background-color: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(25px);
}
.admin_dashobard_container_Left_Box{
    display: flex;
    flex-direction: column;
 align-items: flex-start;
  padding: 30px;
  /* justify-content: space-between; */
}
.admin_dashobard_container_Left_Box_Link{
    font-family: Roboto-Regular !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color:rgba(88, 89, 91, 1) !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 13px !important;
    text-decoration: none !important;
    padding-bottom: 50px;
    cursor: pointer;
}
.active{
    color: #006CB7 !important;

}
.Admin_collaborator_Container{
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(25px);
}
.Home_collaboraotor_view{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.admin_textfield_Box{
    display: flex;
    gap: 14px;
    margin-top: 50px;
    margin-bottom: 20px;
    /* width: 100%; */
    /* background-color: #006CB7; */
}
.table_main_Text{
    font-size: 16px;
    font-weight:600;
    
}
.table_secondary_Text{
    font-size: 14px;
    font-weight: 600;
    color: black;
   
}
.Admin_main_Avatar{
    margin-right: 10px;
}
.Home_collaborator_view{
    padding: 36px;
    display: flex !important;
    overflow-y: auto !important;
    flex: 1 !important;
    

}
.admin_collaborator_main_Text{
    font-size: 20px ;
    font-weight: 600;
   
}
.admin_textfield_Box_Main{
    /* background-color: red; */
    width: 100%;
}
.Admin_textfield1{
    width: 45%;
}
.Admin_textfield2{
    width: 45%;
}
.Admin_textfield3{
    width: 20%;
   
}
.admin_collaborator_main_Grid{
    padding: 20px !important;
    background-image: url("../../assets/img/bg-img.png") ;
    background-repeat: no-repeat;
    background-color: #f0f0f0 !important; 
    background-size: cover !important;
    background-position: center !important;
    position: relative !important;
    gap: 20px !important;
}
.admin_collaborator_profile_Grid{
    background-color: white !important;
  height:30% !important;
}
.admin_collaborator_profile_main_Grid{
    background-color: white !important;
    padding: 4px !important;
    height: auto !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.admin_collaborator_profile_main_Grid_Box{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    height: auto !important;
   
}
.admin_collaborator_profile_main_Grid_Background_Box{
    background-size: cover !important;
    background-position: center !important;
    
    height: 27vh !important;
    padding: 10px !important;
    display: flex !important;
    justify-content: flex-end !important;
  
    width: -webkit-fill-available !important;
}
.admin_collaborator_profile_information_Grid_Background_Box{
   
    height: auto !important;
    display: flex !important;
 
   
}
.avatar_Box{
    border: 2px solid white !important;
    width: 120px !important;
    height: 120px !important;
   
    transform: translate(70%, -70%) !important;
    background-size: cover !important;
    background-position: center !important;
    position: absolute !important;
}
.admin_collaborator_profile_information{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
}
.admin_collaborator_profile_information_Box{
    display: flex !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

}
.progress_main_Grid{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    /* align-items: center !important; */
    padding: 36px ;
    height: auto !important;
    gap: 30px;
}
.progress_secondary_Grid{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items:center !important;
    height: auto !important;
    gap: 20px;
}
.progress_sub_secondary_Grid{
    display: flex;
    flex-direction: column ;
    height: auto !important;
    gap: 20px;
}
.admin_collaborator_main_Grid2{
    /* padding: 20px !important; */
    /* background-image: url("../../assets/img/bg-img.png") ; */
    /* background-repeat: no-repeat; */
    background-color: transparent !important; 
    background-size: cover !important;
    background-position: center !important;
    position: relative !important;
    gap: 20px !important;
   
}
.user_name_Text{
    font-size: 22px;
    font-weight: 600;
    padding-top: 20px;
    font-family: Roboto-Regular;
}
.user_description_Text{
    font-size: 18px;
    font-weight: 500;
   
    font-family: Roboto-Regular;
    padding: 10px;
    text-align: center;
}
.college_name_Text{
    color: #58595B;
   
    
}
.student_button_Admin{
    margin-bottom: 40px;
    margin-top: 20px;
    display: flex;
    gap: 10px;
}
.admin_college_Grid{
    background-color: white !important;
  height: auto !important;
}
.admin_college_Grid_Box{
    display: flex !important;
  flex-direction: column !important;
  padding: 37px !important;
}
.admin_highschool_Grid{
    background-color: white !important;
    height: auto !important;
}
.admin_honors_Grid{
    background-color: white !important;
    height: auto !important;
}
.admin_testing_Grid{
    background-color: white !important;
    height: auto !important;
}
.admin_sports_Grid{
    background-color: white !important;
    height: auto !important;
}
.admin_wokexperience_Grid{
    background-color: white !important;
    height: auto !important;
}   
.admin_essay_Grid{
    background-color: white !important;
    height: auto !important;
}
.admin_college_Grid_Div{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.college_Main_Text{
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 30px;
    font-family: Roboto-Regular;
    color: black;
}
.admin_college_Grid_Div2 {
    display: flex !important;
    flex-direction: column !important;
  }
  .admin_college_Grid_Div3{
 
    display: flex !important;
    flex-direction: row !important;
    gap: 16px !important;
  
  
}
.admin_college-Avatar{
    width: 45px !important;
    height: 45px !important;
  }
  .Honors_Future{
    display: flex;
    flex-direction: column;
    gap: 20px;

  }
  .Honors_sub_Future{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .future-Divider{
    margin-right: 20px;
    margin-left: 20px;
  }
  .admin_sports_Div{
    display: flex;

  }
  .volunteer_Hour_Div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .progress_main_Grid_Text{
    font-size:20px ;
    font-weight: 600;
    margin-bottom: 20px;
    align-self: center;

  }
  .progress_sub_secondary_Grid_Text{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #58595B;
  }
  .CircularProgressWithLabel{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .progress_main_Grid2{
    display: flex;
    gap: 20px;
    overflow: visible; 
    padding: 20px;

  }
  .progress_main_Grid2_Text{
    display: flex;
    flex-direction: column;
    gap: 5px;

  }
  .progress_main_Grid_Avatar{
    width: 35px !important;
    height: 35px !important;
color: #9C27B0;
    

  }
  .progress_main_Grid2_Text_Main{
    font-size: 23px;
    font-weight: 600;
  }
  .progress_main_Grid2_Text_Secondary{
    font-size: 14px;
    font-weight: 400;
    opacity: 80%;
  }
  .Admin_DivCustom{
    display: flex !important;
    flex-direction: column !important;
  }
  .Admin_DivCustom3{
    gap: 5px;
    margin-bottom: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  .Admin_DivCustom4{
    display: flex !important;
    justify-content: space-between !important;
  }
  .Admin_TypographyCustom {
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  
  letter-spacing: 0.15px;
   
  }
  .Admin_TypographyCustom2{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 143%; /* 22.88px */
    letter-spacing: 0.17px;
    padding-bottom: 10px;
    white-space: pre-wrap;
  }
  .Admin_Major_text{
    font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px;
  gap: 5px;
  color: "green";
  }
  .Admin_Supplementary_essay{
    color: var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  }
  .admin_future_Plans_Box{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .Admin_honors_sub_Div{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .Admin_DivCustom100{
    display: flex !important;
    flex-direction: row !important;
    gap: 16px !important;
  
  }
  .Admin_DivCustom17{
    display: flex !important;
    flex-direction: row !important;
    gap: 70px !important;
  }
  .Admin_TypographyCustom_Volunteer {
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  
  letter-spacing: 0.15px;
  margin-bottom: 30px;
   
  }
  .Admin_DivCustom5{
    display: flex !important;
    gap: 10px !important;
  }
  .Admin_BoxCustom6{
    display: flex !important;
    flex-direction: column !important;
    padding: 37px !important;
  }
  .Admin_BoxCustom7{
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 35px !important;
  }
  .Admin_DivCustom_later2{
 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .Admin_DivCustom6{
    display: flex !important;
    gap: 16px !important;
  }
  .Admin_DivCustom7{
    display: flex !important;
    flex-direction: column !important;
    gap: 5px !important;
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .Admin_DivCustom8{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .Admin_DivCustom9{
    display: flex !important;
    gap: 8px !important;
    align-items: center;
    margin-top: 15px;
  }
  .Admin_date_highschool{
    color: #202020;
  
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px;
  }
  .Admin_BoxCustom9{
    display: flex !important;
    flex-direction: column !important;
    padding: 37px !important;
  }
  .Admin_BoxCustom10{
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 35px !important;
  }
  .Admin_DivCustom10{
    display: flex !important;
    flex-direction: row !important;
    gap: 16px !important;
  
  }
  .Admin_TypographyCustom8{
    color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.15px;
  }
  .Admin_DivCustom9{
    display: flex !important;
    gap: 8px !important;
    align-items: center;
  }
  .Admin_DividerCustom3{
    padding-top: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 0px !important;
  }
  .Admin_DividerCustom4{
    border-left: 1px solid #ccc !important;
    height: 100% !important;
  }
  .Admin_BoxCustom15{
    display: flex !important;
    flex-direction: column !important;
    padding: 37px !important;
  }
  .Admin_BoxCustom16{
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 35px !important;
  }
  .Admin_DivCustom_later3{
    padding-top: 15PX;
    display: flex;
    justify-content: space-between;
  }
  .Admin_BoxCustom20 {
    display: flex;
    flex-direction: column;
    padding: 37px;
    /* Add any other styles you need */
  }
  .Admin_BoxCustom21 {
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    justify-content: space-between;
    /* Add any other styles you need */
  }
  .Admin_BoxCustom15{
    display: flex !important;
    flex-direction: column !important;
    padding: 37px !important;
  }
  .Admin_BoxCustom16{
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 35px !important;
  }
  .admin_student_profile_Grid{
    background-color: white !important;
  height:30% !important;
  padding: 36px;
}
.Admin_Student_message_text{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.4%; /* 26.68px */
    padding-bottom: 20px;

  }
  .Admin_message_text_secondary{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.4%; /* 24.012px */
  padding-bottom: 15px;
  padding-top: 15px;
  height: 6vh;
  
  }
  .wrapper {
    display: flex;
    /* min-height: 100vh; */
  }

  .admin_dashobard_container_Left_Box_screen{
    display: flex;
    flex-direction: row;

 
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(25px);
  justify-content: space-evenly;
  align-items: center;
 
  
  /* justify-content: space-between; */
}
/* .css-1gdslaz-MuiGrid-root.admin_dashobard_container_Left_screen {
  display: none;
} */


.admin_dashobard_container_Left_Box_Link_screen{
  font-family: Roboto-Regular !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color:rgba(88, 89, 91, 1) !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 13px !important;
  text-decoration: none !important;
 padding: 20px;
  cursor: pointer;
}
.active {
  color: #006CB7 !important;

}
.TextField_div{
  width: 50%;
}

@media (min-width:901px){
  .admin_dashobard_container_Left_screen{
    display: none !important;
  }
}
  @media (max-width: 900px) {


    .admin_dashobard_container_Left{
      display: none;
    }
    .admin_Login_Grid{
      display: none !important;
    }
    .admin_dashobard_container_Left_screen {
      display: block; /* Show the element */
      width: 100%;
    }
    .css-1gdslaz-MuiGrid-root.admin_dashobard_container_Left_screen {
      display: block;
    }
    .admin_collaborator_main_Text{
      font-size: 16px ;
      font-weight: 600;
     
  }
  .admin_textfield_Box{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two items in one row */
    gap: 14px;
    margin-top: 50px;
    margin-bottom: 20px;
    /* width: 100%; */
    /* background-color: #006CB7; */
}
.Admin_textfield1{
  width: 100%;
}
.Admin_textfield2{
  width: 100%;
}
.TextField_div{
  width: 100%;
}
.college_Main_Text{
  font-size: 18px;
}
.user_name_Text{
  font-size: 18px;
}
.user_description_Text{
  font-size: 16px;
}
.Admin_TypographyCustom{
  font-size: 18px;
}
.Admin_TypographyCustom2{
  font-size: 14px;
}
.Admin_Major_text{
  font-size: 14px
}

  }