.Grid_Collaborator_college_detailpage {
    padding: 20px !important;
    background-image: url("../../assets/img/bg-img.png") ;
    background-repeat: no-repeat;
    background-color: white !important;
    background-size: cover !important;
    background-position: center !important;
    position: relative !important;
  min-height: 92vh;
  }

  .Collaborator_collegeinfo_detailpage{
    background-color: white !important;
    padding: 30px !important;
   height: max-content !important;
  }
  .Collaborator_generalinfo_detailpage{
    background-color: white !important;
    padding: 30px !important;
    height: max-content !important;
  }
  .Collaborator_additionalinfo_detailpage{
    background-color: white !important;
    padding: 30px !important;
    height: max-content !important;
  }
  .Collaborator_recommenderinfo_detailpage{
    background-color: white !important;
    padding: 30px !important;
    height: max-content !important;
  }
  .Collaborator_financialinfo_detailpage{
    background-color: white !important;
    padding: 30px !important;
    height: max-content !important;
  }
  .College_info_Avatar{
    width: 35px !important;
    height: 35px !important;
color: #006CB7;
    

  }
  .College_info_Box1{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  .College_heading{
    font-size: 20px;
    font-weight: 400;
    color: #006CB7;

  }
  .College_info_Box2{
    display: flex;
    align-items: center;
   
    justify-content:space-between ;
   
  }
  .College_info_Box2_secondary1{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 30%;
  }
  .College_info_Box2_secondary11{
    display: flex;
    flex-direction: column;
    gap: 15px;
    
  }
  .College_heading_secondary{
    font-size: 14px;
    font-weight: 400;
    color: #58595B;
  }
  .College_name_secondary{
    font-size: 20px;
    font-weight: 600;
    color: #000000;
  }
  .genral_info_main_Box{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }
  .recommender_info_Box2{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  
  }
  .additional_info_Box1{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  } 
  .Recommenders_info_Box1{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  .Aid_info_Box1{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  .additional_info_Box2_secondary1{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.recommender_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.aid_info_Box2{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.aid_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.aid_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}



.Grid_Collaborator_Highschool_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}


.Collaborator_Highschool_info_detailpage{
  background-color: white !important;
  padding: 30px !important;

}

.Collaborator_Gpa_info_detailpage{
  background-color: white !important;
  padding: 30px !important;
 
}

.Collaborator_coursesinfo_detailpage{
  background-color: white !important;
  padding: 30px !important;

}

.Highschool_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.Gpa_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.courses_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.Highschool_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Highschool_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
 
}
.Highschool_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Gpa_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Gpa_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
  
}
.Gpa_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Courses_info_Box2{
  display: flex;
  align-items: center;
 justify-content: space-between;

}
.Courses_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Grid_Collaborator_Activities_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}
.Collaborator_Activities_info_detailpage{
  background-color: white !important;
  padding: 30px !important;
 height: max-content !important;
 min-height: 100vh !important;
}
.Testing_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.Testing_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Testing_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
  
}
.Testing_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Summary_info_Box2_secondary11{
  display: flex;
  flex-direction: column;
  gap: 15px;
  
}
.Grid_Collaborator_Honors_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
 background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}
.Collaborator_Honors_info_detailpage{
  background-color: white !important;
  padding: 30px !important;
 height: max-content !important;
 min-height: 100vh !important;
}
.Honors_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.Honors_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Honors_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
 
}
.Honors_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Honors_info_Box2_secondary11{
  display: flex;
  flex-direction: column;
  gap: 15px;

}
.Grid_Collaborator_Testing_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}

.Collaborator_Testing_info_detailpage{
  background-color: white !important;
  padding: 30px !important;
 height: max-content !important;
 min-height: 100vh !important;
}

.Sports_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.Sports_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Sports_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
 
}
.Sports_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Sports_info_Box2_secondary11{
  display: flex;
  flex-direction: column;
  gap: 15px;

}


.Grid_Experience_Honors_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}

.Collaborator_Experience_info_detailpage{
  background-color: white !important;
  padding: 30px !important;
 height: max-content !important;
 min-height: 100vh !important;
}
.Experience_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.Experience_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Experience_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;

}

.Experience_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Experience_info_Box2_secondary11{
  display: flex;
  flex-direction: column;
  gap: 15px;

}











.Grid_Student_college_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}
.Student_collegeinfo_detailpage{
  background-color: white !important;
  padding: 30px !important;
 height: max-content !important;
}


.Student_College_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.Student_College_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
  
}
.Student_College_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Student_College_info_Box2_secondary11{
  display: flex;
  flex-direction: column;
  gap: 15px;

}

.Student_generalinfo_detailpage{
  background-color: white !important;
  padding: 30px !important;
  height: max-content !important;
}
.Student_genral_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Student_additionalinfo_detailpage{
  background-color: white !important;
  padding: 30px !important;
  height: max-content !important;
}
.Student_additional_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
} 
.Student_additional_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Student_recommenderinfo_detailpage{
  background-color: white !important;
  padding: 30px !important;
  height: max-content !important;
}
.Student_Recommenders_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.Student_recommender_info_Box2{
  display: flex;
  align-items: center;
 justify-content: space-between;

}
.Student_recommender_info_Box2_secondary1{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.Summary_info_Box2_secondary1{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}



.Grid_Collaborator_Summary_detailpage {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: white !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
min-height: 92vh;
}

.Collaborator_Summary_info_detailpage{
  background-color: white !important;
  padding: 30px !important;
 height: max-content !important;
 min-height: 100vh !important;
}


.Summary_info_Box1{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.Summary_info_main_Box{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Summary_info_Box2{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between ;
  margin-right: 60px;
}
@media only screen  and (max-width: 559px) {
  .recommender_info_Box2{
    gap: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .aid_info_Box2{
    gap: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .Courses_info_Box2{
    display: flex;
    justify-content: space-between;
    gap: 0px;
  
  }
  .Student_recommender_info_Box2{
    gap:0px;
    display: flex;
    justify-content: space-between;
  }
  .College_name_secondary{
    font-size: 16px;
  }
}
@media only screen and (min-width: 560px) and (max-width: 767px) {
  .recommender_info_Box2{
    gap: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .aid_info_Box2{
    gap: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .Courses_info_Box2{
    display: flex;
    justify-content: space-between;
    gap: 0px;
  
  }
  .College_name_secondary{
    font-size: 16px;
  }
  .Student_recommender_info_Box2{
    gap:0px;
    display: flex;
    justify-content: space-between;
  }
}

/* Styles for screens with a minimum width of 768px and a maximum width of 991px (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .recommender_info_Box2{
    gap: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .aid_info_Box2{
    gap: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .Courses_info_Box2{
    display: flex;
    justify-content: space-between;
    gap: 0px;
  
  }
  .Student_recommender_info_Box2{
    gap:0px;
    display: flex;
    justify-content: space-between;
  }
}

/* Styles for screens with a minimum width of 992px and a maximum width of 1199px (small desktops/laptops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* CSS rules specific to large screens (small desktops/laptops) */
}

/* Styles for screens with a minimum width of 1200px (large desktops/laptops) */
@media only screen and (min-width: 1200px) {
  /* CSS rules specific to extra-large screens (large desktops/laptops) */
}