.Note{
    font-size: 20px;
    font-weight: bold;
    color: var(--text-secondary, rgba(0, 0, 0, 0.60));
    line-height: 30px;
}
.Note_secondary{
    color: var(--text-secondary, rgba(0, 0, 0, 0.60));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;    
font-style: normal;
font-weight: 400;
line-height: 150%;
letter-spacing: 0.15px;
}
.css-heg063-MuiTabs-flexContainer {
  display: flex !important;
  justify-content: space-between !important;
}

@media (max-width: 680px) {
  .sudent_editprofile_button{
    width: 100% !important;
        }
  .profile_edit_page_maindiv{
    width: 100% !important;
  }
  .Student_colleges_toppadding{
padding-top: 40px;
  }
.student_collegeAplliedfor_form{
    width: -webkit-fill-available !important;
}
.Student_highschool_form{
    width: 100% !important;
}
.Student_honors_form{
    width: 100% !important;
}
.Student_testing_from{
    width: 100% !important;
}
.Student_Button_activities{
    width: 100% !important;
  }
  .student_activities_form{
    width: 100% !important;
  }
  .Student_summary_form{
    width: 100% !important;
  }
  .Student_summary_button{
    width: 100% !important;
  }
}