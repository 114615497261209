.Premium_text{
    color: var(--material-theme-sys-light-on-primary, #58595B);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 158.333% */
    letter-spacing: 0.17px;
}
.Plan_main{
    color: black;
    font-size: 40px;
    font-weight: 600;


}
.Plan_main2{
    color: black;
    font-size: 30px;
    font-weight: 300;

}
.Personal_text{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto-Regular;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 133.4%; /* 26.68px */
}
.student_colleges_Desc{
  color:  var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B) );
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}
.Students_Honours_level{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 143%;
    letter-spacing: 0.17px;
}
.Students_Honours_desc{
  color:  var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B) );
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
}
.ViewMoreLink{
  color: #006CB7;
  cursor: pointer;
}
.View_more_button{
  color: #006CB7;
  cursor: pointer; 
}
.Student_testing_maindiv{
  padding-top: 15PX;
  display: flex;
  justify-content: space-between;
}
.Student_testing_div1{
  display: flex !important;
  flex-direction: row !important;
  gap: 16px !important;

}
.student_main_text{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px
}
.student_testing_divgap{
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}
.Student_testing_attempts{
  font-weight: 600 !important;
  font-size: 16px !important;
}
.Student_testing_maindiv2{
  display: flex !important;
  flex-direction: row !important;
  gap: 30px !important;
  align-items: stretch !important;
}
.Student_testing_inerdiv{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 15px;
}
.Student_testing_divider{
  border-left: 1px solid #ccc !important;
  height: 100% !important;
}
.Student_Topbar_header_text{
  color: #FFF;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15px;
}
.Personal_text2{
    color:  var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B) );
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400; 
    letter-spacing: 0.17px;
    white-space: pre-wrap;
    
}
.student_major_title{
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}
.Personal_text_main{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.4%; /* 26.68px */
    display: flex;
    align-items: center;

}
.Personal_text3{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 22.88px */
letter-spacing: 0.17px;
}
.payment_text{
    color: var(--material-theme-sys-light-success, #2E7D32);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 133.4%;
}
.payment_desc{
  width: 90%;
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 22.88px */
letter-spacing: 0.17px;
margin-bottom: 40px;

}
.subscription_text{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 146%; /* 26.28px */
letter-spacing: 0.17px;
    }
    .Amount_text{
        color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 146%;
        letter-spacing: 0.17px; 
    }
    .Student_colleges_header{
 
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 35px !important;
      
      
    }
    .Student_colleges_maindiv{
        display: flex !important;
        flex-direction: column !important;
        padding: 37px !important;
      }
      .Student_colleges_addicon{
 
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        flex: 1 !important;
      
      
    }
    .Student_Highschool_maindiv{
        display: flex !important;
        flex-direction: column !important;
        padding: 37px !important;

    }
    .Student_Highschool_header{
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 35px !important;
    }
    .Student_Highschool_addicon{
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        flex: 1 !important;
      }
      .Student_Honors_maindiv{
        display: flex !important;
        flex-direction: column !important;
        padding: 37px !important;
      }
      .Student_Honors_header{
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 35px !important;
      }
      .Student_Honors_addicon{
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        flex: 1 !important;
      }
      .Student_Testing_maindiv{
        display: flex !important;
        flex-direction: column !important;
        padding: 37px !important;
      }
      .Student_Testing_header{
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 35px !important;
      }
      .Student_Testing_addicon{
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        flex: 1 !important;

      }
      .Student_Activities_maindiv{
        display: flex !important;
        flex-direction: column !important;
        padding: 37px !important;
      }
      .Student_Activities_header{
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 35px !important;
      }
      .Student_Activities_addicon{
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        flex: 1 !important;

      }
      .Student_Summary_maindiv{
        display: flex !important;
        flex-direction: column !important;
        padding: 37px !important;
      }
      .Student_Summary_maindiv{
        display: flex;
        flex-direction: column;
       
        /* Add any other styles you need */
      }
      .Student_Summary_header{
        display: flex;
        flex-direction: row;
        margin-bottom: 35px;
        /* Add any other styles you need */
      }
      .Student_Colleges_DivCustom_later{
 
        display: flex;
        justify-content: space-between;
      }
      .Student_Colleges_DivCustom{
        display: flex !important;
        flex-direction: column !important;
      }
      .Student_Colleges_DivCustom2{
 
        display: flex !important;
        flex-direction: row !important;
        gap: 16px !important;
      
      
    }
    .Student_Colleges_DivCustom{
      display: flex !important;
      flex-direction: column !important;
    }
    .Student_Colleges_DivCustom3{
      gap: 5px;
      margin-bottom: 20px !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
    }
    .Student_Colleges_DivCustom4{
      display: flex !important;
      justify-content: space-between !important;
    }
    .Student_Colleges_TypographyCustom{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    
    letter-spacing: 0.15px;
     
    }
    .Student_Colleges_TypographyCustom2{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 143%; /* 22.88px */
      letter-spacing: 0.17px;
    }
    .Student_Colleges_TypographyCustom3{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 143%; /* 22.88px */
      letter-spacing: 0.17px;
    }
    .Student_colleges_DivCustom5{
      display: flex !important;
      gap: 10px !important;
    }
    .Student_DividerCustom{
      padding-top: 30px !important;
      margin-bottom: 30px !important;
      margin-left: 49px !important;
    }
    .Student_Colleges_BoxCustom5{
      display: flex !important;
      flex-direction: column !important;
    
    
    }
    .Student_Highschool_GridItemCustom3{
      background-color: white !important;
      height: auto !important;
    }
    .Student_Highschool_DivCustom_later2{
 
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .Student_Highschool_DivCustom6{
      display: flex !important;
      gap: 16px !important;
    }
    .Student_Highschool_DivCustom7{
      display: flex !important;
      flex-direction: column !important;
      gap: 5px !important;
      width: 100% !important;
    }
    .Student_Highschool_DivCustom8{
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      width: 100% !important;
    }
    .Student_Highschool_TypographyCustom5{
      color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.15px;
    }
    .Student_Highschool_DivCustom9{
      display: flex !important;
      gap: 8px !important;
      align-items: center;
    }
    .Student_Honors_GridItemCustom4{
      background-color: white !important;
      height: auto !important;
    }
    .Student_Honors_DivCustom10{
      display: flex !important;
      flex-direction: row !important;
      gap: 16px !important;
    
    }
    .Student_Honors_DivCustom11{
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
    }
    .Student_Honors_DivCustom12{
      margin-bottom: 20px !important;
      display: flex !important;
      flex-direction: column !important;
      gap: 5px !important;
    }
    .Student_Honors_TypographyCustom8{
      color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.15px;
    }
    .Student_Grid_main_profile{
      padding: 20px !important;
      background-image: url("../../assets/img/bg-img.png") ;
      background-repeat: no-repeat;
      background-color: #f0f0f0 !important; /* Set your background color */
      background-size: cover !important;
      background-position: center !important;
      position: relative !important;
    }
    .Student_Grid_item_custom{
      padding: 4px !important;
      display: flex !important;
      flex-direction: column !important;
      gap: 20px !important;
    }
    .Student_GridItemCustom2{
      background-color: white !important;
      padding: 4px !important;
      height: auto !important;
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .Student_BoxCustom{
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: flex-end !important;
      height: 27vh !important;
      position: relative !important;
    }
    .Student_BoxCustom_main{
      height: 40vh;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      position: relative;
    }
    .Student_profile_view_mainDiv2{
      display: flex;
      flex-direction: column;
  margin-bottom: 2%;
      align-items: center;
  
  
    }
    .Student_Profile_view_welcome{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 133.4%; 
      margin-bottom: 14px;
    }
    .Student_Complete_profile{
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 12px;
    }
    .Student_GridItemCustom{
      background-color: white !important;
      height: auto !important;
    }
    .Student_Profile-view{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
     /* padding: 20px; */
     
     gap: 6px;
     padding: 30px;
     
     
    }
    .Student_Profile_view_text{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      text-align: center;
   
      font-family: Roboto-Medium;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 19px;
     
    }
    .Student_Profile_view_location{
      font-family: Roboto;
      gap: 5px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      display: flex;
      align-items: center;
      color:rgba(88, 89, 91, 1);
      margin-bottom: 4px
      
    }
    .Student_Profile_view_date{
      font-family: Roboto;
      gap: 5px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      display: flex;
      align-items: center;
      color:rgba(88, 89, 91, 1);
      margin-bottom: 19px;
    
    }
    .Student_Button_one{
      color: var(--material-theme-sys-light-primary-1, #006CB7);
  
  /* button/small */
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.46px;
  text-transform: uppercase;
    }
    .Student_profile_view_Button_div{
      display: flex;
      flex-direction: column;
   align-items: flex-start;
    padding: 30px;
    justify-content: space-between;
    }
    .Student_Profile_view_Button{
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 24px;
      color:rgba(88, 89, 91, 1);
      display: flex;
      align-items: center;
      gap: 13px;
    }
    .active {
      color:#006CB7;
    }
    .Student_profile_view_mainDiv{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%; /* Ensure the container takes up the full height of the parent Grid item */
    }
    .Student_profile-view-main{
      height: 73px;
      width: 78px;
          background-repeat: no-repeat;
          margin-bottom: 35px;
        }
        .Student_Profile_view_welcome{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto-Regular;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 133.4%; 
          margin-bottom: 14px;
        }
        .Student_Profile_view_desc{
          color: var(--material-theme-sys-light-onboldtext, rgba(0, 0, 0, 0.87));
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 143%;
          letter-spacing: 0.17px;
          margin-bottom: 8px;
          text-align: center;
        }
        .Student_message_text{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 133.4%; /* 26.68px */
          padding-bottom: 20px;
      
        }
        .Student_message_text_secondary{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto-Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.4%; /* 24.012px */
        padding-bottom: 15px;
        padding-top: 15px;
        height: 6vh;
        
        }
        .Student_message_text_secondary2{
          color: var(--material-theme-sys-light-on-primary, #58595B);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 143%; /* 20.02px */
        letter-spacing: 0.17px;
        
        }
        .Student_message_text_secondary3{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 133.4%; /* 24.012px */
          padding-bottom: 25px;
          padding-top: 8px;
          height: 5vh;
        
        }
        .Premium_text_main{
          color: #FB9700;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 30px */
letter-spacing: 0.15px;
        }
        .Student_message_text_secondary21{
          color: var(--material-theme-sys-light-on-primary, #58595B);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 143%; /* 20.02px */
        letter-spacing: 0.17px;
        margin-bottom: 10px;
        }
        .workDate{
          color: #2E7D32;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 22.88px */
letter-spacing: 0.17px;

        }
        .Student_Edit_icon_profile{
          display: flex;
          justify-content: flex-end;
          padding: 30px;
        }
        .job_text{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 420;
line-height: 143%; /* 22.88px */
letter-spacing: 0.17px;
        }
        .Student_Testing_Obtained_text {
          font-size: 16px !important;
        }
        .Collaborator_highschool_view_divider1{
          display: none !important;
        }
        @media (max-width: 680px) {
          .collaborator_view_experience_div{
            padding-top: 20px !important;
            padding-left: 0px !important;
            padding-right: 60px !important;
            padding-bottom: 60px !important;
          }
          .Sports_collaborator_view{
            display: none !important;
          }
          .Collaborator_view_sports{
            display: grid !important;
            grid-template-columns: repeat(1,1fr) !important;
          }
          .Collaborator_view_testing_maindiv{
            display: grid !important;
            grid-template-columns: repeat(1,1fr) !important;
          }
          .collaborator_view_Testing_Divider{
            display: none !important;
          }
          .Collaborator_view_colleges_div{
            width: auto !important;
            gap: 10px;
          }
          .Collaborator_colleges_view_maindiv{
            display: grid !important;
            grid-template-columns: repeat(1, 1fr) !important;
          }
          .Personal_text{
            font-size: 18px;
          }
          .Personal_text_main{
font-size: 16px;
          }
          .Collaborator_view_avatar_img{
            width: 27px !important;
            height: 27px !important;
          }
          .Collaborator_highschool_view_divider1{
            display: flex !important;
            width: -20% !important;
          }
          .Collaborator_highschool_view_divider{
            display: none !important;
          }
          .Collaborator_view_highschool_divs{
            gap: 20px !important;
            display: grid !important;
            grid-template-columns: repeat(2,1fr) !important;
          }
          .collaborator_highschool_view_text{
            margin-bottom: 0px !important;
          }
          .Collaborator_view_details_div{
            padding: 20px !important;
          }
          .Messaging_collaborator_view_hide{
            display: none !important;
          }
          .Student_profile_view_mainDiv2{
            margin-bottom: 20px;
          }
          .collaborator_view_profile_maindiv{
            height: 16vh !important;
          }
          .collaborator_view_profile_coverimg{
            height: 15vh !important;
          }
          .SpanCustom{
            line-height: 0 !important;
          }
          .Student_Testing_Obtained_text {
            font-size: 14px !important;
          }
          .View_as_collaborator{
            margin-bottom: 20px !important;
          }
          .Student_BoxCustom {
            height: 16vh !important;
          }
          .Student_BoxCustom_main{
            height: 15vh !important;
          }
          .Student_notification_div{
            min-height: 92vh !important;
          }
          .Student_message_maindiv{
            height: auto !important;
          }
          .Student_search_collaborators_divider{
            display: none !important;
          }
          .Student_search_collaborators{
            display: grid !important;
            grid-template-columns: repeat(1,1fr) !important;
          }
          .student_collaborator_divider{
            display: none !important;
          }
          .Student_collaborator_view{
            display: grid !important;
            grid-template-columns: repeat(1,1fr) !important;
          }
          .Student_Profile_view_date{
            display: none;
          
          }
          .Student_Profile_view_location{
            display: none;
          } 
          .Avatat_student{
            height: 13vh !important;
            width: 13vh !important;
            top: 30% !important;
          }
          .profile-view-bg{
            height: 15vh;
          }
          .Student_Profile-view {
            padding: 15px !important;
          }
          .Student_collaborator_Main_messsage_request{
            padding: 20px !important;
          }
          .student_search_collaborator{
            padding: 20px !important;
          }
          .student_search_collaboartor_maindiv{
            gap: 15px !important;
          }
          .Student_suggested_collaborator_div{
            padding: 20px !important;
          }
          .student_search_barsdiv{
            margin-top: 20px !important;
          }
          .Student_collaboartor_maindiv{
            gap: 15px !important;
          }
          .Student_messages_page_maindiv{
            padding: 20px !important;
          }
          .Student_search_messagediv{
            min-height: 100vh !important;
          }
          .Student_message_text{
            font-size: 18px;
          }
          .Student_message_text_secondary{
            padding-bottom: 10px;
          }
          .student_suggested_studentsdiv{
            padding: 20px !important;
          }
          .Student_collaborator_messagediv{
            padding:30px!important;;
          }
          .student_notification_maindiv{
            min-height: 92vh !important;
          }
          .Student_Message_requestDiv{
            padding: 30px !important;
          }
          .Student_Profile_view_desc{
            font-size: 18px;
          }
          .Student_Profile_view_text{
            margin-bottom: 10px;
          }
          .Student_Profile_view_welcome{
            margin-bottom: 4px;
          }
          .Student_profile-view-main{
            height: 47px;
            width: 49px;
            margin-bottom: 15px;
          }
          .Hidden_Student2{
            min-height: 92vh !important;
            gap: 15px !important;
            display: flex !important;
            align-content: flex-start !important;
              }
              .main_menu_Student{
        
                height: fit-content !important;
                padding: 18px !important;
                }
                .Hidden_student3{
                  height:fit-content!important;
                  position: static !important;
                    }
                    .Hidden_student{
                      display: none !important;
                    }
                    .Student_profile_paymentdiv{
                      display: none !important;
                    }

                    .Student_colleges_maindiv{
                      
                      padding: 20px !important;
                    }


                    .Student_Highschool_maindiv{
                
                      padding: 20px !important;
              
                  }
                  .Student_Honors_maindiv{
                    padding: 20px !important;
                  }
                  .Student_Testing_maindiv{
                    padding: 20px !important;
                  }
                  .Student_Activities_maindiv{
                  
                    padding: 20px !important;
                  }
                  .Student_Activities_display{
                    display: grid;
                    grid-template-columns: repeat(1,1fr) !important;
                  }
                  .Student_colleges_interested_text{
                    font-size: 18px !important;
                  }
                  .student_testing_text{
                    font-size: 18px !important;
                  }
                  .student_div_Avatar_img{
                    width: 27px !important;
                    height: 27px !important;
                  }
                  .AvatarCustom2{
                    width: 27px !important;
                    height: 27px !important;
                  }
                  .Student_Colleges_DivCustom2{
                    gap: 8px !important;
                  }
                  .Student_Highschool_DivCustom6{
                    gap: 8px !important;
                  }
                  .Student_Honors_DivCustom10 {
                    gap: 8px !important;
                  }
                  .Student_testing_div1{
                    gap: 8px !important;
                  }
                  .Student_Colleges_TypographyCustom{
                    font-size: 16px;
                  }
                  .Student_Highschool_TypographyCustom5 {
                    font-size: 16px;
                  }
                  .Student_Honors_TypographyCustom8{
                    font-size: 16px;
                  }
                  .student_main_text{
                    font-size: 16px;
                  }
                  .student_colleges_Desc {
                    font-size: 14px;
                  }
                  .Students_Honours_desc{
                    font-size: 14px;
                  }
                  .Student_testing_attempts{
                    font-size: 13px !important;
                  }
                  .Student_testing_divider{
                    display: none !important; 
                  }
                  .Student_testing_inerdiv{
                    justify-content:  flex-end !important;
                    gap: 8px;
                  }
                  .Student_colleges_DivCustom5{
                    gap: 6px !important;
                  }
                  .Collaborator_view_highschool_name{
                    width: 130% !important;
                  }
        }
        @media (min-width: 681px) {
        
          .main_menu_Student{
            display: none !important;
            height: fit-content !important;
            padding: 6px !important;
            }
        }

        @media only screen and (max-width: 600px) {
          .payment_text {
            font-size: 18px; /* Adjust font size for small screens */
          }
        
          .payment_desc {
            font-size: 14px; /* Adjust font size for small screens */
            margin-bottom: 16px; /* Add some space at the bottom */
          }
        
          .subscription_text,
          .Amount_text {
            font-size: 12px; /* Adjust font size for small screens */
          }
        }
        @media (min-width: 680px) and (max-width: 1024px) {
          .Personal_text2 {
            color: var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B));
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.17px;
          }
          .Personal_text_main{
            font-size: 16px;
                      }
        }
        
        .Student_Topbar_header_text_first{
          color: rgba(255, 255, 255, 0.7);
        
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto-Regular;
          font-size: 22px;
          font-style: normal;
        
          
          letter-spacing: 0.25px;
        }
        .topBar{
          display: flex;
          flex-direction: row;
          gap: 5;
        }


        .Student_profile_view_mainDiv_notification {
          display: flex;
          flex-direction: column;
          
          align-items: flex-start;
       
          text-align: center;
   
          padding: 20px;
          width: 100%;
        }
        .Student_Profile_view_welcome{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto-Regular;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 133.4%; 
          margin-bottom: 14px;
        }
        .Student_Profile_view_Admin{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto-Regular;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          /* line-height: 133.4%; 
          margin-bottom: 14px; */
        }

        .Student_Profile_view_Admin2{
          color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto-Regular;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          /* line-height: 133.4%; 
          margin-bottom: 14px; */
        }
    
    