/* Define your CSS classes here */
.profile-container {
    padding: 20px;
    height: 92vh;
    background-repeat: no-repeat;
    background-color: #f0f0f0; /* Use your desired background color */
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .profile-avatar {
    margin-bottom: 50px;
    height: 20vh;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    border: 2px solid white;
    width: 80px;
    height: 80px;
    top: 80%;
  }
  .Submitted_text{
    color: var(--material-theme-sys-light-pending, #EF6C00);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* typography/h4 */
font-family: Roboto;
font-size: 34px;
font-style: normal;
font-weight: 400;
line-height: 123.5%; /* 41.99px */
letter-spacing: 0.25px;
  }


  
  /* Add more CSS classes as needed */
  .Profile-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   /* padding: 20px; */
   
   gap: 6px;
   padding: 30px;
   
   
  }
  .delete_confirm{
    justify-content:center ;
    display: flex;
    color: var(--material-theme-sys-light-error, #D32F2F);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto-Regular;
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: 133.4%; 
  }
  .Profile_view_text{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
    text-align: center;
 
    font-family: Roboto-Medium;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 19px;
   
  }
  /* .message_text{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  
 
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 20px;

  } */
  .Complete_profile_desc{
    gap: 6px;
font-size: 14px;
font-weight: 400;
line-height: 20px;
color: rgba(0, 0,0,0.6 );
display: flex;
align-items: center;
margin-bottom: 35px;
  }
  .Complete_profile_view{
    background-color:black ;
    height: 40%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .Profile_view_location2{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color:rgba(88, 89, 91, 1);
    gap: 4px;
    
  }
  .Complete_profile{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 12px;
  }
  .Profile_view_date2{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color:rgba(88, 89, 91, 1);
  gap: 4px;
  }
  .Profile_view_location{
    font-family: Roboto;
    gap: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color:rgba(88, 89, 91, 1);
    margin-bottom: 4px
  }
  .university-names {
    display: flex;
    flex-direction: column;
  }
  
  .university-name {
    display: flex;
  }
  
  .university-name:not(:last-child) {
    border-right: 1px solid #006CB7; /* Adjust the color and thickness as needed */
    padding-right: 30px; /* Add padding to create space between names and divider */
    /* Add margin for better spacing */
  }
  .Profile_view_location2{
    font-family: Roboto;
    gap: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color:rgba(88, 89, 91, 1);
    /* margin-bottom: 4px */
  }   
  .Button_one{
    color: var(--material-theme-sys-light-primary-1, #006CB7);

/* button/small */
font-family: Roboto;
font-size: 13px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.46px;
text-transform: uppercase;
  }
  .Profile_view_date{
    font-family: Roboto;
    gap: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color:rgba(88, 89, 91, 1);
    margin-bottom: 19px;
  
  }
  .Profile_view_Button{
    font-family: Roboto-Regular;
    font-size: 16px;
    line-height: 24px;
    color:rgba(88, 89, 91, 1);
    display: flex;
    align-items: center;
    gap: 13px;
  }
  .profile_view_Button_div{
    display: flex;
    flex-direction: column;
 align-items: flex-start;
  padding: 30px;
  justify-content: space-between;
  }
  .profile-view-bg {
    margin-bottom: 10px;
    height: 20vh;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* You can apply additional background-image styling here */
  }
  .profile-view-main {
height: 73px;
width: 78px;
    background-repeat: no-repeat;
    margin-bottom: 35px;
  }
  .Profile_view_welcome{
    color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto-Regular;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.4%; 
    margin-bottom: 14px;
  }
  .Profile_view_desc{
    text-align: center;
    color: var(--material-theme-sys-light-onboldtext, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
    margin-bottom: 8px;
  }
  .Profile_view_desc2{
    text-align: center;
    font-family: "Roboto";
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    display: flex;
    font-weight: 600;
    margin-bottom: 35px;
  }
  .profile_view_mainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the container takes up the full height of the parent Grid item */
  }
  .Chatname_text{
    font-size: 15px;
    font-weight: bold;
  }
  .Chatname_text2{
    font-size: 15px;
  }
  .profile_view_mainDiv2 {
    display: flex;
    flex-direction: column;
margin-bottom: 2%;
    align-items: center;


  }
  .Profile_view_Percent{
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .Profile_view_Percent2{
    gap: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 30px;
    color:rgba(0, 0, 0, 0.6);
    font-family: Roboto-Regular;
  }
  .profile_view_Percet_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
  }
  .Submitted_text2{
   text-align: center;
width: 350px;
padding: 16px;
align-items: center;
line-height: 30px;
font-size: 20px;
font-weight: 600;
  }
  .Submitted_text22{
    text-align: center;
    font-weight: 600;
 padding-top: 16px;
 align-items: center;
 line-height: 30px;
 font-size: 20px;
   }
.Bold_text{
  font-weight: 600;
}
/* .backdropStyle{
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: open ? 'block' : 'none',
  zIndex: 1000, 
} */
.backdropStyle{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: 'rgba(0, 0, 0, 0.5)' ;
 z-index: 1000;
}
.message_text{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 133.4%; /* 26.68px */

}
.message_Search_bartext{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 133.4%; /* 26.68px */
margin-bottom: 20px;

}
.message_text_secondary{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto-Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 133.4%; /* 24.012px */
padding-bottom: 15px;
padding-top: 15px;
height: 6vh;

}
.message_text_secondary2{
  color: var(--material-theme-sys-light-on-primary, #58595B);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 20.02px */
letter-spacing: 0.17px;

}
.message_text_secondary21{
  color: var(--material-theme-sys-light-on-primary, #58595B);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 20.02px */
letter-spacing: 0.17px;
margin-bottom: 10px;
}
.message_text_secondary3{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%; /* 24.012px */
  padding-bottom: 25px;
  padding-top: 8px;
  height: 5vh;

}
.active {
  color:#006CB7;
}
.Grid_main_profile {
  padding: 20px !important;
  background-image: url("../../assets/img/bg-img.png") ;
  background-repeat: no-repeat;
  background-color: #f0f0f0 !important; /* Set your background color */
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
}
.Grid_item_custom {
  padding: 4px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
}
.GridItemCustom2{
  background-color: white !important;
  padding: 4px !important;
  height: auto !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.BoxCustom {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-end !important;
  height: 27vh !important;
  position: relative !important;
}
.upload-icon{
  color: white !important;
  cursor: pointer !important;
}
.AvatarCustom {
  border: 2px solid white !important;
  width: 120px !important;
  height: 120px !important;
  /* bottom: -27% !important; */
  transform: translate(-50%, -70%) !important;
  background-size: cover !important;
  background-position: center !important;
  position: absolute !important;
}
.Topbar_header_text{
  color: #FFF;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.15px;
}
.GridItemCustom {
  background-color: white !important;
  height: auto !important;
}
.GridItemCustom_grid_circular {
  background-color: white !important;
  height: auto !important;
}
.BoxCustom2{
  display: flex !important;
  flex-direction: column !important;
  padding: 37px !important;
}
.BoxCustom3{
 
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 35px !important;
  
  
}
.BoxCustom4{
 
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    flex: 1 !important;
  
  
}
.DivCustom {
  display: flex !important;
  flex-direction: column !important;
}
.DivCustom2{
 
    display: flex !important;
    flex-direction: row !important;
    gap: 16px !important;
  
  
}
.DivCustom3{
  gap: 5px;
  margin-bottom: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}
.DivCustom4{
  display: flex !important;
  justify-content: space-between !important;
}
.TypographyCustom {
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto-Regular;
font-size: 20px;
font-style: normal;
font-weight: 600;

letter-spacing: 0.15px;
 
}
.Major_text{
  font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 22.88px */
letter-spacing: 0.17px;
}
.Supplementary_essay{
  color: var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 143%;
letter-spacing: 0.17px;
}
.DivCustom5{
  display: flex !important;
  gap: 10px !important;
}
.TypographyCustom2{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px;
}
.TypographyCustom3{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px;
}
.DividerCustom {
  padding-top: 30px !important;

  margin-left: 49px !important;
}
.BoxCustom5{
  display: flex !important;
  flex-direction: column !important;


}
.Message_sender_name{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 27px */
letter-spacing: 0.15px;
}
.Message_sender_detail{
  color: var(--text-secondary, rgba(0, 0, 0, 0.60));
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 20.02px */
letter-spacing: 0.17px;
}
.Personal_text_main {
  text-decoration: none;
  transition: transform 0.2s ease ;
  display: inline-block; 
}

.Personal_text_main:hover {
  /* transform: translateY(-4px); */
  transform: translate3d(0, -3px, 0);

}
.Message_users{
  
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
}
.GridItemCustom3{
  background-color: white !important;
  height: auto !important;
}
.BoxCustom6{
  display: flex !important;
  flex-direction: column !important;
  padding: 37px !important;
}
.BoxCustom7{
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 35px !important;
}
.TypographyCustom4{
  font-weight: 600 !important;
  font-size: 20px !important;
}
.BoxCustom8{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex: 1 !important;
}
.DivCustom6{
  display: flex !important;
  gap: 16px !important;
}
.DivCustom7{
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.degree_name{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px; 
}
.date_highschool{
  color: #202020;

font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 143%; /* 22.88px */
letter-spacing: 0.17px;
}
.DivCustom8{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 600px) {
  .DivCustom8 {
    white-space: normal;
  }
}
.TypographyCustom5{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.DivCustom9{
  display: flex !important;
  gap: 8px !important;
  align-items: center;
}
.TypographyCustom6{
  font-weight: 500 !important;
  font-size: 16px !important;
}
.DividerCustom2{
  padding-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 49px !important;
}
.GridItemCustom4{
  background-color: white !important;
  height: auto !important;
}
.BoxCustom9{
  display: flex !important;
  flex-direction: column !important;
  padding: 37px !important;
}
.BoxCustom10{
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 35px !important;
}
.TypographyCustom7{
  font-weight: 600 !important;
  font-size: 20px !important;
}
.BoxCustom11{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex: 1 !important;
}
.DivCustom10{
  display: flex !important;
  flex-direction: row !important;
  gap: 16px !important;

}
.DivCustom11{
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}
.DivCustom12{
  margin-bottom: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
}
.Honours_level{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 143%;
    letter-spacing: 0.17px;
}
.Honours_desc{
  color:  var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B) );
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
}
.TypographyCustom8{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.DividerCustom3{
  padding-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 49px !important;
}
.GridItemCustom5{
  background-color: white !important;
  height: auto !important;
}
.BoxCustom12{
  display: flex !important;
  flex-direction: column !important;
  padding: 37px !important;
}
.BoxCustom13{
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 35px !important;
}
.TypographyCustom9{
  font-weight: 600 !important;
  font-size: 20px !important;
}
.BoxCustom14{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex: 1 !important;
}
.LinkCustom {
  text-decoration: none !important;
  color: black !important;
}
.DivCustom14{
  display: flex !important;
  gap: 30% !important;
  padding-top: 20px !important;
  width: fit-content !important;
}
.DivCustom15{
  display: flex !important;
  flex-wrap: nowrap !important;
  gap: 50% !important;
  padding-bottom: 40px !important;
}
.DivCustom16{
  display: flex !important;
  flex-wrap: nowrap !important;
  gap: 50% !important;
  padding-bottom: 40px !important;
}
.DivCustom17{
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}
.TypographyCustom10{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px
}
.TypographyCustom11{
  font-weight: 600 !important;
  font-size: 16px !important;
}
.CalendarTodayIconCustom {
  height: 15px !important;
  width: 15px !important;
}
.DivCustom18{
  display: flex !important;
  flex-direction: row !important;
  gap: 30px !important;
  align-items: stretch !important;
}
.DividerCustom4{
  border-left: 1px solid #ccc !important;
  height: 100% !important;
}
.DivCustom19{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 15px;
}
.Summary_desc{
  color:  var(--material-theme-sys-light-on-primary, var(--text-primary, #58595B) );
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  white-space: pre-wrap;
}
.GridItemCustom6{
  background-color: white !important;
  height: auto !important;
}
.BoxCustom15{
  display: flex !important;
  flex-direction: column !important;
  padding: 37px !important;
}
.BoxCustom16{
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 35px !important;
}
.TypographyCustom12{
  font-weight: 600 !important;
  font-size: 20px !important;
}
.BoxCustom17{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex: 1 !important;
}
.BoxCustom18{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex: 1 !important;
}
.DivCustom20{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.Personal_sports_text3{
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px;
}
.DivCustom21 {
  display: flex;
  flex-direction: row;
  gap: 50%;
  /* Add any other styles you need */
}
.DivCustom22 {
  display: flex;
  flex-direction: row;
 
  gap: 16px;
  /* Add any other styles you need */
}

.AvatarCustom2 {
  width: 87px;
  height: 87px;
  /* Add any other styles you need */
}
.DivCustom23 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Add any other styles you need */
}
.TypographyCustom13{
  color:  var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87) ));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.TypographyCustom14{
  font-weight: 600;
  font-size: 16px;
  /* Add any other styles you need */
}
.LinkCustom2{
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  /* Add any other styles you need */
}
.BoxCustom19{
  display: flex;
  flex-direction: column;
  /* Add any other styles you need */
}
.GridItemCustom7 {
  background-color: white;

  height: auto;
  /* Add any other styles you need */
}
.BoxCustom20 {
  display: flex;
  flex-direction: column;
  padding: 37px;
  /* Add any other styles you need */
}
.BoxCustom21 {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  justify-content: space-between;
  /* Add any other styles you need */
}
.DividerCustom44 {
  border-top: 1px solid #ccc !important;
  width: 100% !important;
}
.TypographyCustom14 {
  color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 22.88px */
  letter-spacing: 0.17px;
}
.BoxCustom22 {
  display: flex;
  flex-direction: column;
 
  /* Add any other styles you need */
}
.BoxCustom23 {
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
  /* Add any other styles you need */
}
.TypographyCustom15 {
  font-weight: 600;
  font-size: 20px;
  /* Add any other styles you need for the Typography component */
}
.GridItemCustom8 {
  background-color: white !important;
  height: 30%;
 
  /* Add any other styles you need */
}
.BoxCustom_main {
  height: 40vh;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.Text_decoration{
  text-decoration: none;
  width: fit-content;
}
.DivCustom_later{
 
  display: flex;
  justify-content: space-between;
}
.DivCustom_later2{
 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CalenderCustom{
  height: 15px;
  width: 15px;
}
.DivCustom_later3{
  padding-top: 15PX;
  display: flex;
  justify-content: space-between;
}
.SpanCustom{
  font-weight: 600;
  font-size: 18px;
}
.Edit_icon_profile{
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.Testing_Obtained_text{
  font-size: 16px !important;
}
.Dialog_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.Success {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  max-width: 100%; /* Ensure text stays within the div */
}
/* @media (max-width: 600px) {
  .Dialog_content {
    padding: 10px;
  }

  .Success {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .Submitted_text22 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .Submitted_text2 {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .Dialog_content Button {
    width: 100%;
    max-width: 100%; 
    padding-bottom: 10px;
  }

} */

@media (max-width: 680px) {
  .Testing_Obtained_text{
    font-size: 14px !important;
  }
  .DivCustom19{
    justify-content: flex-end !important;
    gap: 8px;
  }
  .TypographyCustom11{
    font-size: 13px !important;
  }
  .Summary_desc{
    font-size: 14px;
  }
  .Personal_text2{
    font-size: 14px !important;
  }
  .Honours_desc{
    font-size: 14px;
  }
  .Supplementary_essay{
    font-size: 14px;
  }
  .DivCustom6{
    gap: 6px !important;
  }
  .DivCustom22{
    gap: 6px !important;
  }
  .DivCustom10{
    gap: 6px !important;
  }
  .DivCustom5{
gap: 6px !important;
  }
  .Mui_edit_icon{
    width:0.7em !important;
  }
  .Chip_div{
    display: none !important;
  }
  .Collaborator_Delete_Div{
    padding: 8px !important;
  }
  
  .DivCustom2{
gap:8px !important ;
  }
  .Colleges_Avatar_img{
    width: 27px !important;
    height: 27px !important;
  }
  .TypographyCustom13{
    font-size: 16px;
  }
  .TypographyCustom10{
    font-size: 16px;
  }
  .TypographyCustom8{
    font-size: 16px;
  }
  .TypographyCustom5 {
    font-size: 16px;
  }
  .TypographyCustom {
    font-size: 16px;
  }
  .Profile_view_Percent{
    font-size: 18px;
  }
  .AvatarCustom{
    height: 13vh !important;
    width: 13vh !important;
  }
  .BoxCustom_main{
    height: 15vh;
  }
  .BoxCustom{
    height: 16vh !important;
  }
  .Notification_main_grid{
    min-height: 92vh !important;
  }
  .Messages_search_div{
    padding: 20px !important;
  }
  .message_text_secondary{
    padding-bottom: 10px;
  }
  .collaborator_view_mainGrid{
    row-gap: 15px !important;
  }
  .Messages_chatBox_maindiv{
    min-height: 92vh !important;
  }
  .Collaborator_students_viewdiv{
    padding: 20px !important;
  }
  .Message_sender_detail{
    display: none ;
  }
  .Messages_box_main_div{
    padding: 20px !important;
  }
  .Messages_box_Avatar{
    width: 40px !important;
    height: 40px !important;
  }
  .Collaborators_views{
    display: grid !important;
    grid-template-columns: repeat(1,1fr) !important;
  }
  .collaborator_search_view{
    display: grid !important;
    grid-template-columns: repeat(1,1fr) !important;
  }
  .collaborator_search_view_divider{
    display: none !important;
  }
  .collaborator_views_divider{
    display: none !important;
  }
  .Messages_chat_div{
    padding: 20px !important;
  }
 .Topbar_user_img{
  width: 25px !important;
  height: 25px !important;
 }
 .Collaborator_Norequestdiv{
  padding:30px!important;
 }
 .Collaborator_student_maingrid{
  padding: 20px !important;
 }
  .Avatat_collaborator{
    height: 13vh !important;
    width: 13vh !important;
    top: 30% !important;
  }
  .message_text{
    font-size: 18px;
  }
  .Send_button_div{
    min-width: 40px !important;
  }
  .Search_bar_maindiv{
    margin-top: 20px !important;
  }
  .message_Search_bartext{
    font-size: 18px;
  }
  .Search_student_2nddiv{
    padding: 20px !important;
  }
  .Search_student_maindiv{
    padding: 20px !important;
  }
  .Messages_typing_div{
    padding: 10px !important;
  }
  .Profile_view_welcome{
    font-size: 22px;
    margin-bottom: 4px;
  }
  .Profile_view_desc2{
    font-size: 18px;
  }
  .profile-view-main{
    height: 47px;
    width: 49px;
    margin-bottom: 15px;
  }
  .Profile_view_text{
    margin-bottom: 10px;
  }
  .Profile-view{
    padding: 15px;
  }
  .profile-view-bg{
    height: 15vh;
  }
  .Profile_view_date{
    display: none;
  
  }
  .Profile_view_location2{
    display: none;
  }  
  .Hidden_collaborator {
    display: none !important;
  }
  .Hidden_collaborator2{
min-height: 92vh !important;
gap: 15px !important;
display: flex !important;
align-content: flex-start !important;
  }
  .Hidden_collaborator3{
    height:fit-content!important;
    position: static !important;
      }
      .main_menu_collaborator{
        
        height: fit-content !important;
        padding: 18px !important;
        }
        .Home_collaboraotor_view{
          height: 60vh !important;
        }
        .Collaborator_completeprofile_activities{
          display: grid !important;
          grid-template-columns: repeat(1,1fr) !important;
        }
        .Collaborator_colleges_appliedto{
font-size: 18px !important;
        }
        .TypographyCustom4{
          font-size: 18px !important;
        }
        .TypographyCustom7{
font-size: 18px !important;
        }
        .TypographyCustom9{
          font-size: 18px !important;
        }
        .TypographyCustom12{
          font-size: 18px !important;
        }
        .TypographyCustom15{
          font-size: 18px !important;
        }
        .BoxCustom2{
         
          padding: 20px !important;
        }
        .BoxCustom6{
     
          padding: 20px !important;
        }
        .BoxCustom9{
          padding: 20px !important;
        }
        .BoxCustom12{
        
          padding: 20px !important;
        }
        .BoxCustom15{
          padding: 20px !important;
        }
        .BoxCustom20 {
        
          padding: 20px !important; 
        }
        .GridItemCustom8{
          display: none !important;
        }
        .Profile_view_desc{
          font-size: 18px;
          margin-bottom: 0px;
        }
        .DividerCustom4{
          display: none;
        }
        .Collaboartor_viewas_student{
          margin-bottom: 20px !important;
        }
        .Submitted_text2{
          width: fit-content !important;
        }
        .Collaborator_Profile_view_Admin{
          font-size: 14px !important;
        }
      .Collaborator_Profile_view_Admin2{
        font-size: 14px !important;
      }
}
@media (min-width: 681px) {
.main_menu_collaborator{
display: none !important;
height: fit-content !important;
padding: 6px !important;
}
.GridItemCustom_grid_circular {
display: none !important ;
}
}

@media (max-width: 890px) {
  .Collaborators_views{
    display: grid !important;
    grid-template-columns: repeat(1,1fr) !important;
  }
}
.Collaborator_profile_view_mainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the container takes up the full height of the parent Grid item */
}
.Collaborator_profile-view-main{
  height: 73px;
  width: 78px;
      background-repeat: no-repeat;
      margin-bottom: 35px;
    }
 .Collaborator_Profile_view_welcome{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 133.4%; 
      margin-bottom: 14px;
    }
 .Collaborator_Profile_view_Admin{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      min-width: fit-content;
      /* line-height: 133.4%; 
      margin-bottom: 14px; */
    }
 .Collaborator_Profile_view_Admin2{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      text-align:start;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 133.4%; 
      margin-bottom: 14px;
    }
 .Collaborator_Profile_view_Admin2_date{
      color: var(--material-theme-sys-light-onboldtext, var(--text-primary, rgba(0, 0, 0, 0.87)));
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      /* line-height: 133.4%;  */
      /* margin-bottom: 14px; */
      
    }



    .analytics_Div {
      display: flex;
    }
    
    @media (max-width: 600px) {
      .analytics_Div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .Admin_DividerCustom4 {
        display: none;
      }
    }


    .Collaborator_profile_view_mainDiv_notification {
      display: flex;
      flex-direction: column;
      
      align-items: flex-start;
    
      text-align: center;

  
      padding: 20px;
      width: 100%;
    }




