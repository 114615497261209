@media (min-width: 681px) {
.Menu_icon{
    display: none  !important;
  
} 

}
@media (max-width: 680px) {


    .Main_button{
        display: none !important;
        
            }
            .Main_getstarted{
                display: none !important;
              
            }
            .Main_container{
                justify-content: space-between;
                padding-left: 20px !important;
                padding-right: 10px !important;
            }
}