.risingStarsText{
    color: var(--Gray0, #2D2F31);
  font-family: Circe;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  
  letter-spacing: -0.722px;
  }
  .CommentContainerLeft-Child-secondaryText{
    color:  #2D2F31;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  
  }
  @media (max-width: 680px) {
    .Question_maindiv{
      padding-bottom: 100px !important;
    }
    .risingStarsText{
      font-size: 40px;
      line-height: 45px;
      text-align: center;
    }
  }